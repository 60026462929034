<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
        <v-layout
                justify-center
                class="overflow-x-auto"
                wrap
        >
            <template v-if="loaded">
                <data-table ref="datatable"
                            :api-end-point="apiurl"
                            :isCustomFilter="false"
                            :search="search"
                            :sort-order="sortOrder"
                            :table-name="'Transfers'"
                            :pre-selected-filters="filters.selectedFilters"
                >
                    <template slot="filters">
                        <filters :filters="filters.timeFilters"></filters>
                    </template>
                </data-table>
            </template>

        </v-layout>
    </v-container>
</template>

<script>
    import DataTable from "../components/common/dataTable/DataTable";
    import Filters from "../components/common/dataTable/filters/Filters";

    export default {
        name: "Transfers",
        components: {DataTable, Filters},
        data() {
            return {
                apiurl: 'transfers',
                search:{
                    placeholder:  'Search by promoter name or status',
                    searchAble: true
                },
                sortOrder: [
                    {
                        field: 'updated_at',
                        sortField: 'updated_at',
                        direction: 'desc'
                    }
                ],
                filters: null,
                loaded: false
            }
        },
        created() {
            this.fetchFilters();
        },
        methods: {
            fetchFilters(){
                this.$http.get(this.url+'filters/transfers').then((res) => {
                    if(res.data) {
                        this.filters = res.data;
                        this.loaded = true;
                    } else {
                        alert('there is some problem');
                    }
                });
            },
        }
    }
</script>
